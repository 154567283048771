import type { FC, ReactNode } from "react";
import React from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { useAuth } from "hooks/useAuth";

interface AuthGuardProps {
  children: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const router = useRouter();
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (!auth.isAuthenticated) {
      router.push({
        pathname: "/login",
        query: {
          returnUrl: decodeURIComponent(router.asPath) === "/change-password" ? "" : router.asPath,
        },
      });
    } else {
      setChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.
  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
