import { AuthGuard } from "components/auth/AuthGuard";
import { SplashScreen } from "components/SplashScreen";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import React from "react";

const Home: NextPage = () => {
  const router = useRouter();
  React.useEffect(() => {
    router.push("/dashboard");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <SplashScreen />;
};

Home.getLayout = (page) => <AuthGuard>{page}</AuthGuard>;
export default Home;
